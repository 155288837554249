import React from "react";
import { Col, Container } from 'reactstrap';
import StaticPageLogo from '../../logo';
import Image from "next/legacy/image";
import Link from 'next/link';

import instagramIllustration from "/public/assets/images/instagram.png";
import twitterIllustration from "/public/assets/images/twitter.png";

const Footer = () => {
  const links = [
    {
      title: 'Company',
      col: 3,
      children: [
        { label: 'About us', link: '/about-us' },
        { label: 'Why us', link: '/why-us' },
        { label: 'Contact us', link: '/contact-us' },
      ],
    },
    {
      title: 'Products',
      col: 3,
      children: [
        { label: 'NCCI Medicare Lite', link: '/tools/ncci-lite/medicare-demo' },
        { label: 'NCCI Medicare Pro', link: '/tools/ncci-pro/medicare-demo' },
        { label: 'NCCI Medicaid Lite', link: '/tools/ncci-lite/medicaid-demo' },
        { label: 'NCCI Medicaid Pro', link: '/tools/ncci-pro/medicaid-demo' },
      ],
    },
    {
      title: 'Help',
      col: 3,
      children: [
        { label: 'FAQ', link: '/' },
        { label: 'Support', link: '/' },
        { label: 'Developers', link: '/' },
      ],
    },
    {
      title: 'Legal',
      col: 2,
      children: [
        { label: 'Terms of Use', link: '/terms-of-use' },
        { label: 'Privacy Policy', link: '/privacy-policy' },
        { label: 'EULA', link: '/end-user-license-agreement' },
      ],
    },
  ];
  return (
    <Container fluid className="p-0">
      <footer>
        <div className="d-flex flex-column">
          <div className="d-flex flex-wrap">
            <Col xs={12} lg={3}>
              <StaticPageLogo />
              <p className="mt-3 font-500 font-size-13 hc-c-slate-500">
                Advanced coding and billing tools for ICD-10-CM/PCS, CPT, HCPCS.
                Search tools, index look-ups, crosswalks, and tips for payment
                integrity solutions.
              </p>
            </Col>

            <Col lg={9}>
              <div className="d-flex flex-wrap footer-link-container">
                {links.map((link, item) => (
                  <div key={item} className="d-flex flex-column footer-column">
                    <h3 className="font-size-14 hc-c-blue-900">{link.title}</h3>
                    <ul className="footer-list">
                      {link.children.map((c, i) => (
                        <li key={i}>
                          <Link href={c.link} legacyBehavior>
                            <a className="font-size-14 hc-c-blue-800">
                              <u>{c.label}</u>
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </Col>
          </div>
          <Col className="footer-copyright-container">
            <div className="footer-copyright">
              <p className="font-size-14 font-500 hc-c-slate-400">
                Copyright© {new Date().getFullYear()} - Present Avandé Venture Studio, LLC. All rights reserved.
              </p>
              <div className="social-medias">
                <a href="#">
                  <span className="mx-2">
                    <div className="next-image-twitter">
                      <Image src={twitterIllustration} width={25} height={25} alt="twitter" />
                    </div>
                  </span>
                </a>
                <a href="#">
                  <span>
                    <div className="next-image-instagram">
                      <Image src={instagramIllustration} width={25} height={25} alt="instagram" />
                    </div>
                  </span>
                </a>
              </div>
            </div>
          </Col>
        </div>
      </footer>
    </Container>
  );
};

export { Footer };
